/*
This component is the parent component for the medication list,
this is where the user selects medication.
*/

import React, {Component} from 'react';
import MedList from './MedList';
import { connect } from 'react-redux';
import { getPatientsMedication, clearComplete } from '../../actions/medsActions';
import { withRouter } from 'react-router-dom';
import { 
  setPatientMed, 
  setPatientFormattedMed, 
  restorePatientMed, 
  restorePatientFormattedMed, 
  clearMedication } from '../../actions/patientSessionActions';
import { 
  activateModal, 
  deactivateModal, 
  setMedToSkip, 
  setReasonToSkip, 
  clearReasons, 
  activateOtherModal, 
  deactivateOtherModal, 
  setAsNeededMed, 
  setAsNeededReason, 
  setAsNeededReminderTime,
  clearAsNeededModalData,
  activatePatientSignature,
  activateMedtechSignature,
 } from '../../actions/modalActions';
 import { setNarcoticToSign, setFormattedNarcotic } from '../../actions/narcoticActions';
import { clearDomain } from '../../actions/domainActions';
import { logOut } from '../../actions/loginActions';
import { getDate } from '../../shared/utils';
import './medicationSelectionStyles.css';

const DOMAIN_EXTENSION = process.env.REACT_APP_DOMAIN;


class MedicationSelection extends Component {
  componentDidMount() {
   const { domain, patient, medsType, loginResponse, schedule, location } = this.props;
   const { headers } = loginResponse;

   if (schedule !== '') {
      this.props.getPatientsMedication(`https://${domain}.${DOMAIN_EXTENSION}`, headers, patient.id, medsType, schedule)
   }
  }

  setNarcoticMed = (med) => {
    const  formattedNarcotic  = this.formatMed(med, 'takePill');
    this.props.setNarcoticToSign(med);
    this.props.setFormattedNarcotic(formattedNarcotic)
    this.props.history.push('/medication_signing');
  }

  componentDidUpdate(prevProps) {
    const { domain, patient, medsType, loginResponse, schedule } = this.props;
    const { headers } = loginResponse;
    if(prevProps.schedule !== this.props.schedule) {
      this.props.getPatientsMedication(`https://${domain}.${DOMAIN_EXTENSION}`, headers, patient.id, medsType, schedule)
    }
  }

  back = () => {
    this.props.clearMedication();
    this.props.clearReasons();
    this.props.clearComplete();
    this.props.history.push('/schedule');
  }

  continue = () => {
    this.props.history.push('/medication_schedule_confirmation');
  }

  setMed = (med, type) => {
    const { schedule, medsType } = this.props;
    const medication = this.formatMed(med, type);
    if(schedule === 'as_needed' && type === 'takePill'){
        this.props.setAsNeededMed(med);
        this.props.activateOtherModal(true);
    } else if(schedule === 'as_needed' && type === 'refusedPill'){
        this.props.setAsNeededMed(med);
        this.props.setMedToSkip(med)
        this.props.activateModal(true);
    } else if(medsType === 'narcotic' && type === 'refusedPill') {
        this.setNarcoticMedTwo(med)
        this.props.setPatientMed(med);
        this.props.setMedToSkip(med)
        this.props.activateModal(true);
    } else if(medsType === 'narcotic') {
        this.setNarcoticMedTwo(med)
        this.props.setPatientMed(med);
        this.props.setPatientFormattedMed(medication);
    } else if(type === 'refusedPill'){
        this.props.setMedToSkip(med)
        this.props.activateModal(true);
    } else {
        this.props.setPatientMed(med);
        this.props.setPatientFormattedMed(medication);
    }
  }

  setNarcoticMedTwo = (med) => {
    const  formattedNarcotic  = this.formatMed(med, 'takePill');
    this.props.setNarcoticToSign(med);
    this.props.setFormattedNarcotic(formattedNarcotic)
  }
  
  formatMed = (med, type) => {
    const { reasons, schedule, asNeededReason, asNeededReasonReminderTime} = this.props;
    let formattedMed;
    schedule === 'as_needed'
    ?
    formattedMed = {
      "patient_id": med.patient_id,
      "med_id": med.id,
      "event_name": type,
      "event_date": getDate() ,
      "postponed_until": '',
      "symtoms": "",
      "notes": reasons,
      "reason": asNeededReason,
      "reminder": asNeededReasonReminderTime,
      "schedule": med.schedule,
    }
    : 
    formattedMed = {
      "patient_id": med.patient_id,
      "med_id": med.id,
      "event_name": type,
      "event_date": getDate() ,
      "postponed_until": '',
      "symtoms": "",
      "notes": reasons,
      "schedule": med.schedule,
    }
    return formattedMed
  }
  
  restoreMed = (med) => {
    console.log('this is the med', med, 'compared to', this.props.formattedSelectedMedication)
    const { formattedSelectedMedication, restorePatientMed, selectedMedication, restorePatientFormattedMed} = this.props;
    restorePatientFormattedMed(med, formattedSelectedMedication);
    restorePatientMed(med, selectedMedication);
  }

  filterTaken = () => {
    const { meds } = this.props
    const filteredMeds = meds.filter(med =>(
      !med.disabled
    ))
    console.log('filtered', filteredMeds);
    return filteredMeds;
  }

  render() {
    const { 
      schedule, 
      meds, 
      formattedSelectedMedication, 
      selectedMedication, 
      complete,
      medsType
    } = this.props;

    const filteredMeds = meds && this.filterTaken();
    console.log(filteredMeds)
    return(
      <>
        <div className="status-title-container">
          <p className="status-title-text">You will take these {medsType === 'narcotic' ? 'narcotics' : 'medications'}</p>
        </div>
        {
          schedule !== '' && meds
          ?
            <>
              <MedList meds={meds} selectMed={this.setMed} selectedMedication={selectedMedication} formattedSelectedMedication={formattedSelectedMedication} restore={this.restoreMed} schedule={schedule} complete={complete} setNarcotic={this.setNarcoticMed} />
              <div className="break" />
              {/*<Buttons amount={schedule === 'as_needed' ? 2: filteredMeds.length === 0 ? 1: formattedSelectedMedication.length === filteredMeds.length ? 2 : 1 } options={{back: this.back, continue: this.continue}} />*/}
            </>
          :
            <div style={{display: 'flex', justifyContent: 'center'}}><p>select schedule</p></div>
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  meds: state.meds.meds,
  complete: state.meds.complete,
  patient: state.patientSession.patient,
  medsType: state.patientSession.medicationType,
  schedule: state.patientSession.schedule,
  domain: state.domain.domain,
  loginResponse: state.login.loginResponse,
  formattedSelectedMedication: state.patientSession.formattedMedication,
  selectedMedication: state.patientSession.medication,
  status: state.patientSession.status,
  medTech: state.patientSession.med_tech,
  reasons: state.modal.reasons,
  colors: state.settings.settings,
  skippedMed: state.modal.medToSkip,
});

export default connect(mapStateToProps, { 
  getPatientsMedication, 
  setPatientMed, 
  setPatientFormattedMed, 
  restorePatientMed, 
  activateModal, 
  deactivateModal, 
  setMedToSkip, 
  restorePatientFormattedMed, 
  clearMedication, 
  logOut, 
  clearDomain, 
  setReasonToSkip, 
  clearReasons, 
  activateOtherModal, 
  deactivateOtherModal, 
  setAsNeededMed, 
  setAsNeededReason, 
  setAsNeededReminderTime,
  clearAsNeededModalData,
  clearComplete,
  activatePatientSignature,
  activateMedtechSignature,
  setNarcoticToSign,
  setFormattedNarcotic,
})(withRouter(MedicationSelection));