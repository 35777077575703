const LOGIN = 'LOGIN';
const SET_DOMAIN = 'SET_DOMAIN';
const GET_DOMAIN = 'GET_DOMAIN';
const SET_TOKEN = 'SET_TOKEN';
const GET_MEDS = 'GET_MEDS';
const GET_PATIENTS = 'GET_PATIENTS';
const SET_PASSWORD = 'SET_PASSWORD';
const SET_ACCOUNT = 'SET_ACCOUNT';
const SET_USER = 'SET_USER';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const SET_SEARCH = 'SET_SEARCH';
const SET_EMOJI = 'SET_EMOJI';
const SET_STATUS = 'SET_STATUS';
const SET_PATIENT_INFO = 'SET_PATIENT_INFO';
const SET_MED = 'SET_MED';
const SET_MEDS = 'SET_MEDS';
const SET_PATIENT_SIGNATURE = 'SET_PATIENT_SIGNATURE';
const SET_MEDTECH_SIGNATURE = 'SET_MEDTECH_SIGNATURE';
const SET_MEDICATION_TYPE = 'SET_MEDICATION_TYPE';
const SET_MEDICATION_SCHEDULE = 'SET_MEDICATION_SCHEDULE';
const SET_MEDTECH_INFO = 'SET_MEDTECH_INFO';
const SET_FORMATTED_MEDICATION = 'SET_FORMATTED_MEDICATION';
const RESTORE_MED = 'RESTORE_MED';
const ACTIVATE_MODAL = 'ACTIVATE_MODAL';
const DEACTIVATE_MODAL = 'DEACTIVATE_MODAL';
const SET_MED_TO_SKIP = 'SET_MED_TO_SKIP';
const SET_SCHEDULE_CHANGE = 'SET_SCHEDULE_CHANGE';
const SET_SCHEDULE_CHANGE_FORMATTED = 'SET_SCHEDULE_CHANGE_FORMATTED';
const SET_SCHEDULE_CHANGE_MED = 'SET_SCHEDULE_CHANGE_MED';
const GET_PATIENT_REFILLS = 'GET_PATIENT_REFILLS';
const SET_REFILL_TO_SIGN = 'SET_REFILL_TO_SIGN';
const MED_EVENT_SUCCESS = 'MED_EVENT_SUCCESS';
const MED_EVENT_FAILED = 'MED_EVENT_FAILED';
const CLEAR_MED_EVENT = 'CLEAR_MED_EVENT';
const CLEAR_PATIENT_SESSION = 'CLEAR_PATIENT_SESSION';
const SET_NARCOTIC_TO_SIGN = 'SET_NARCOTIC_TO_SIGN';
const SET_FORMATTED_NARCOTIC = 'SET_FORMATTED_NARCOTIC';
const DELETE_NARCOTIC_FROM_LIST = 'DELETE_NARCOTIC_FROM_LIST';
const ACTIVATE_OTHER_MODAL = 'ACTIVATE_OTHER_MODAL';
const DEACTIVATE_OTHER_MODAL = 'DEACTIVATE_OTHER_MODAL';
const CLEAR_PARTIAL_SESSION = 'CLEAR_PARTIAL_SESSION';
const UPDATE_AVAILABLE_NARCOTIC_SUCCESS = 'UPDATE_AVAILABLE_NARCOTIC_SUCCESS';
const UPDATE_AVAILABLE_NARCOTIC_FAILED = 'UPDATE_AVAILABLE_NARCOTIC_FAILED';
const SET_AVAILABLE_NARCOTIC_AMOUNT = 'SET_AVAILABLE_NARCOTIC_AMOUNT';
const SET_AMOUNT_TO_SEND = 'SET_AMOUNT_TO_SEND';
const RESTORE_FORMATTED_MED = 'RESTORE_FORMATTED_MED';
const CLEAR_MEDICATION = 'CLEAR_MEDICATION';
const LOGOUT = 'LOGOUT';
const CLEAR_DOMAIN = 'CLEAR_DOMAIN';
const SET_DOMAIN_FLAG = 'SET_DOMAIN_FLAG';
const SET_AMEND = 'SET_AMEND';
const ACTIVATE_LOGIN_ALERT = 'ACTIVATE_LOGIN_ALERT';
const SET_REASON_TO_SKIP = 'SET_REASON_TO_SKIP';
const CLEAR_REASONS = 'CLEAR_REASONS';
const SET_SCHEDULE_TO_CHANGE = 'SET_SCHEDULE_TO_CHANGE';
const CLEAR_EMOJI = 'CLEAR_EMOJI';
const SET_AS_NEEDED_MED = 'SET_AS_NEEDED_MED';
const SET_AS_NEEDED_REASON_TO_SKIP = 'SET_AS_NEEDED_REASON_TO_SKIP';
const SET_LOCK_SCREEN_PASSWORD = 'SET_LOCK_SCREEN_PASSWORD';
const CLEAR_LOCK_SCREEN_PASSWORD = 'CLEAR_LOCK_SCREEN_PASSWORD';
const ACTIVATE_LOCK_SCREEN = 'ACTIVATE_LOCK_SCREEN';
const BLOCK = 'BLOCK';
const SET_PAD_STYLE = 'SET_PAD_STYLE';
const SET_AS_NEEDED_REASON_REMINDER_TIME = 'SET_AS_NEEDED_REASON_REMINDER_TIME';
const CLEAR_AS_NEEDED_REASONS_MODAL_DATA = 'CLEAR_AS_NEEDED_REASONS_MODAL_DATA';
const SET_OTHER_PAD_STYLE = 'SET_OTHER_PAD_STYLE';
const CLEAR_PATIENT_SIGNATURE = 'CLEAR_PATIENT_SIGNATURE';
const CLEAR_MEDTECH_SIGNATURE = 'CLEAR_MEDTECH_SIGNATURE';
const CLEAR_COMPLETE = 'CLEAR_COMPLETE';
const GET_SETTINGS = 'GET_SETTINGS';
const ACTIVATE_PATIENT_SIGNATURE = 'ACTIVATE_PATIENT_SIGNATURE';
const ACTIVATE_MEDTECH_SIGNATURE = 'ACTIVATE_MEDTECH_SIGNATURE';
const GET_WORKSPACES = 'GET_WORKSPACES';
const SET_VITAL_SIGN = 'SETTING_VITAL_SIGN';
const SET_DIAGNOSES = 'SETTING_DIAGNOSES';
const SET_RISK_FACTOR = 'SETTING_RISK_FACTOR';
const SET_TEMPERATURE = 'SET_TEMPERATURE';
const SET_BLOOD_PRESSURE = 'SET_BLOOD_PRESSURE';
const SET_HEART_RATE = 'SET_HEART_RATE';
const SET_RESPIRATORY_RATE = 'SET_RESPIRATORY_RATE';
const SET_OXYGEN_SATURATION = 'SET_OXYGEN_SATURATION';
const SET_WEIGHT = 'SET_WEIGHT';
const SET_HEADACHE = 'SET_HEADACHE';
const SET_FEVER = 'SET_FEVER';
const SET_COUGH = 'SET_COUGH';
const SET_BREATHING_DIFFICULTY = 'SET_BREATHING_DIFFICULTY';
const SET_LOW_BREATHING_RATE = 'SET_LOW_BREATHING_RATE';
const SET_LOW_SYSTOLIC_BLOOD_PRESSURE = 'SET_LOW_SYSTOLIC_BLOOD_PRESSURE';
const SET_LOW_COMA_GLASHOW_SCALE = 'SET_LOW_COMA_GLASHOW_SCALE';
const SET_EXACERBATION_OF_BLOOD_GLUCOSE_CONTROL = 'SET_EXACERBATION_OF_BLOOD_GLUCOSE_CONTROL';
const SET_PULSE_OXIMETER_AVAILABILITY = 'SET_PULSE_OXIMETER_AVAILABILITY';
const SET_LOW_OXYGEN_SATURATION = 'SET_LOW_OXYGEN_SATURATION';
const DIAGNOSE_PATIENT_SUCCESS = 'DIAGNOSE_PATIENT_SUCCESS';
const DIAGNOSE_PATIENT_FAILURE = 'DIAGNOSE_PATIENT_FAILURE';
const RESET_DIAGNOSES = 'RESET_DIAGNOSES';
const GET_MEDTECH_DATA = 'GET_MEDTECH_DATA';
const GET_TABS = 'GET_TABS';
const GET_TABS_SUCCESS = 'GET_TABS_SUCCESS';
const GET_TABS_FAILURE = 'GET_TABS_FAILURE';
const GET_LOGS_PATIENTS = 'GET_LOGS_PATIENTS';
const GET_LOGS_PATIENTS_SUCCESS = 'GET_LOGS_PATIENTS_SUCCESS';
const GET_LOGS_PATIENTS_FAILURE = 'GET_LOGS_PATIENTS_FAILURE';
const CHANGE_MAIN_TAB = 'CHANGE_MAIN_TAB';
const CHANGE_TAB = 'CHANGE_TAB';
const UPDATE_LOG_UNITS = 'UPDATE_LOG_UNITS';
const UPDATE_LOG_ID = 'UPDATE_LOG_ID';
const CREATE_LOG_SUCCESS = 'CREATE_LOG_SUCCESS';
const UPDATE_LOG_SUCCESS = 'UPDATE_LOG_SUCCESS';
const DELETE_LOG = 'DELETE_LOG';
const DELETE_LOG_SUCCESS = 'DELETE_LOG_SUCCESS';
const DELETE_LOG_FAILURE = 'DELETE_LOG_FAILURE';
const CHANGE_SEARCH = 'CHANGE_SEARCH';
const UPDATE_MEDTECH_DATA = 'UPDATE_MEDTECH_DATA';
const UPDATE_MEDTECH_DATA_SUCCESS = 'UPDATE_MEDTECH_DATA_SUCCESS';
const UPDATE_MEDTECH_DATA_FAILURE = 'UPDATE_MEDTECH_DATA_FAILURE';
const OPTIONS_MODAL = 'OPTIONS_MODAL';
const HANDLE_FAILURE = 'HANDLE_FAILURE';

export {
  LOGIN,
  SET_DOMAIN,
  GET_DOMAIN,
  SET_TOKEN,
  GET_MEDS,
  GET_PATIENTS,
  SET_PASSWORD,
  SET_ACCOUNT,
  SET_USER,
  LOGIN_SUCCESS,
  SET_SEARCH,
  SET_EMOJI,
  SET_STATUS,
  SET_PATIENT_INFO,
  SET_MED,
  SET_MEDS,
  SET_PATIENT_SIGNATURE,
  SET_MEDTECH_SIGNATURE,
  SET_MEDICATION_TYPE,
  SET_MEDICATION_SCHEDULE,
  SET_MEDTECH_INFO,
  SET_FORMATTED_MEDICATION,
  RESTORE_MED,
  ACTIVATE_MODAL,
  DEACTIVATE_MODAL,
  SET_MED_TO_SKIP,
  SET_SCHEDULE_CHANGE,
  SET_SCHEDULE_CHANGE_FORMATTED,
  SET_SCHEDULE_CHANGE_MED,
  GET_PATIENT_REFILLS,
  SET_REFILL_TO_SIGN,
  MED_EVENT_SUCCESS, MED_EVENT_FAILED,
  CLEAR_MED_EVENT,
  CLEAR_PATIENT_SESSION,
  SET_NARCOTIC_TO_SIGN,
  SET_FORMATTED_NARCOTIC,
  DELETE_NARCOTIC_FROM_LIST,
  ACTIVATE_OTHER_MODAL,
  DEACTIVATE_OTHER_MODAL,
  CLEAR_PARTIAL_SESSION,
  UPDATE_AVAILABLE_NARCOTIC_SUCCESS,
  UPDATE_AVAILABLE_NARCOTIC_FAILED,
  SET_AVAILABLE_NARCOTIC_AMOUNT,
  SET_AMOUNT_TO_SEND,
  RESTORE_FORMATTED_MED,
  CLEAR_MEDICATION,
  LOGOUT,
  CLEAR_DOMAIN,
  SET_DOMAIN_FLAG,
  SET_AMEND,
  LOGIN_FAILURE,
  ACTIVATE_LOGIN_ALERT,
  SET_REASON_TO_SKIP,
  CLEAR_REASONS,
  SET_SCHEDULE_TO_CHANGE,
  CLEAR_EMOJI,
  SET_AS_NEEDED_MED,
  SET_AS_NEEDED_REASON_TO_SKIP,
  SET_LOCK_SCREEN_PASSWORD,
  CLEAR_LOCK_SCREEN_PASSWORD,
  ACTIVATE_LOCK_SCREEN,
  BLOCK,
  SET_PAD_STYLE,
  SET_AS_NEEDED_REASON_REMINDER_TIME,
  CLEAR_AS_NEEDED_REASONS_MODAL_DATA,
  SET_OTHER_PAD_STYLE,
  CLEAR_MEDTECH_SIGNATURE,
  CLEAR_PATIENT_SIGNATURE,
  CLEAR_COMPLETE,
  GET_SETTINGS,
  ACTIVATE_PATIENT_SIGNATURE,
  ACTIVATE_MEDTECH_SIGNATURE,
  GET_WORKSPACES,
  SET_VITAL_SIGN,
  SET_DIAGNOSES,
  SET_RISK_FACTOR,
  SET_TEMPERATURE,
  SET_BLOOD_PRESSURE,
  SET_HEART_RATE,
  SET_RESPIRATORY_RATE,
  SET_OXYGEN_SATURATION,
  SET_WEIGHT,
  SET_HEADACHE,
  SET_COUGH,
  SET_FEVER,
  SET_BREATHING_DIFFICULTY,
  SET_LOW_BREATHING_RATE,
  SET_LOW_SYSTOLIC_BLOOD_PRESSURE,
  SET_LOW_COMA_GLASHOW_SCALE,
  SET_EXACERBATION_OF_BLOOD_GLUCOSE_CONTROL,
  SET_PULSE_OXIMETER_AVAILABILITY,
  SET_LOW_OXYGEN_SATURATION,
  DIAGNOSE_PATIENT_SUCCESS,
  DIAGNOSE_PATIENT_FAILURE,
  RESET_DIAGNOSES,
  GET_MEDTECH_DATA,
  GET_TABS,
  GET_TABS_SUCCESS,
  GET_TABS_FAILURE,
  GET_LOGS_PATIENTS,
  GET_LOGS_PATIENTS_SUCCESS,
  GET_LOGS_PATIENTS_FAILURE,
  CHANGE_MAIN_TAB,
  CHANGE_TAB,
  UPDATE_LOG_UNITS,
  UPDATE_LOG_ID,
  CREATE_LOG_SUCCESS,
  UPDATE_LOG_SUCCESS,
  DELETE_LOG,
  DELETE_LOG_SUCCESS,
  DELETE_LOG_FAILURE,
  CHANGE_SEARCH,
  UPDATE_MEDTECH_DATA,
  UPDATE_MEDTECH_DATA_SUCCESS,
  UPDATE_MEDTECH_DATA_FAILURE,
  OPTIONS_MODAL,
  HANDLE_FAILURE
};